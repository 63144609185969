import to from 'await-to-js';
import { ActionTree } from 'vuex';
import { firestoreAction } from 'vuexfire/src/index';
import { bloqify, bloqifyAuth, bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
import VueRouter from '@/boot/router';
import { CurrentManagerHydrator } from '@/hydrator/managers/CurrentManagerHydrator';
import { State } from '@/models/State';
import { Settings } from '@/models/Common';

// todo change users to managers -> do not use new user before that is done
class Reference {
  name: string = '';
  ref: any;
  options?: any;
}

interface UnbindReference {
  name: string;
  reset?: boolean | Function;
}

export interface GetCollectionParams {
  investorId?: string
  investmentId?: string
  assetId?: string
  where?: [string | firebase.firestore.FieldPath, firebase.firestore.WhereFilterOp, any][]
}

let resolver: firebase.auth.MultiFactorResolver | undefined;

export default <ActionTree<State, State>>{
  /**
   * Bind Firestore references.
   *
   * @see https://github.com/posva/vuexfire/tree/firestore
   */
  bindFirestoreReference: firestoreAction((
    { bindFirestoreRef },
    { name, ref, options }: Reference,
  ): Promise<any> => bindFirestoreRef(name, ref, options)),

  bindFirestoreReferences: firestoreAction(async (
    { bindFirestoreRef },
    references: Reference[] | Reference,
  ): Promise<any> => {
    if (references instanceof Array) {
      let results: any[] = [];

      try {
        results = await Promise.all(
          references.map(({ name, ref, options }): Promise<any> => bindFirestoreRef(name, ref, options)),
        );
      } catch (err) {
        console.log('err: ', err); // eslint-disable-line
      }

      return results;
    }

    return bindFirestoreRef(references.name, references.ref);
  }),

  unbindFirestoreReference: firestoreAction((
    { unbindFirestoreRef },
    { name, reset }: UnbindReference,
  ): void => {
    if (reset === undefined) {
      unbindFirestoreRef(name);
    } else {
      // @ts-ignore
      unbindFirestoreRef(name, reset);
    }
  }),

  unbindFirestoreReferences: firestoreAction((
    { unbindFirestoreRef },
    references: UnbindReference[],
  ): void => {
    references.forEach(({ name, reset }): void => {
      if (reset === undefined) {
        unbindFirestoreRef(name);
      } else {
        // @ts-ignore (wrong official types)
        unbindFirestoreRef(name, reset);
      }
    });
  }),

  /**
   * Resetting bound slice of state
   * @param context Vuex Context
   * @param payload Name of the slice of state
   */
  resetStateSlice(
    { commit, state },
    { name }: { name: keyof State },
  ): void {
    commit('resetSliceState', { name, payload: state[name] instanceof Array ? [] : null });
  },

  /**
   * Outline toggle.
   */
  enableOutlineMode({ commit }): void {
    commit('enableOutlineMode');
  },

  disableOutlineMode({ commit }): void {
    commit('disableOutlineMode');
  },

  toggleOutlineMode({ commit }): void {
    commit('toggleOutlineMode');
  },

  /**
   * We need to set current user to be able to always
   * have the logged in user available in the state.
   *
   * @see ./vue.ts
   */
  async setAuthenticatedUser(
    { commit },
    { user }: { user: firebase.User },
  ): Promise<void> {
    commit('setcurrentManager', await CurrentManagerHydrator.hydrate(user));
  },

  /**
   * Get auth session ID token again. Force-
   * changes a roles/claim or status change
   * client side.
   */
  async refreshAuthenticatedUserToken(
    { commit },
  ): Promise<void> {
    const user = bloqifyAuth.currentUser as firebase.User;

    if (user) {
      commit('setcurrentManager', await CurrentManagerHydrator.hydrate(user, true));
    }
  },

  /**
   * Login.
   */
  async logIn(
    { commit, dispatch },
    { email, password }: { email: string, password: string },
  ): Promise<void> {
    commit('loginProcessing', { name: 'login' });

    // Existing and future Auth states are now persisted in the current
    // session only. Closing the window would clear any existing state even
    // if a user forgets to sign out.
    const [setPersistenceError] = await to(bloqifyAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION));
    if (setPersistenceError) {
      commit('loginError', { name: 'login', error: { message: 'Persistence error.' } });
      return;
    }

    // New sign-in will be persisted with session persistence.
    const [error, userCredential] = await to<firebase.auth.UserCredential, firebase.auth.Error>(
      bloqifyAuth.signInWithEmailAndPassword(email, password),
    );
    if (error) {
      // @ts-ignore
      if (error.resolver) {
        // @ts-ignore
        resolver = error.resolver;
      }

      commit('loginError', { name: 'login', error: { code: error.code, message: error.message } });
      return;
    }

    const tokenResult = await userCredential!.user!.getIdTokenResult();
    if (!tokenResult.claims.superadmin && !tokenResult.claims.admin && !tokenResult.claims.editor && !tokenResult.claims.manager) {
      dispatch('logOut');
      commit('loginError', { name: 'login', error: { message: 'Account not found.' } });
      return;
    }

    commit('loginSuccess', { name: 'login' });
  },

  /**
   * Second step in login flow when the user is already enrolled; verifies the phone number.
   */
  async loginStepTwo(
    { commit },
    { appVerifier }: { appVerifier: firebase.auth.RecaptchaVerifier_Instance },
  ): Promise<void> {
    commit('loginProcessing', { name: 'loginStepTwo' });

    if (!resolver) {
      commit('loginError', { name: 'loginStepTwo', error: Error('There was a problem login in. Error #1 (resolver).') });
      return;
    }

    const factorId = firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID;
    const hint = resolver.hints.find((tempHint): boolean => tempHint.factorId === factorId)!;
    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: resolver.session,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    const [verifyError, verificationId] = await to(phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier));
    if (verifyError) {
      commit('loginError', { name: 'loginStepTwo', error: verifyError });
      return;
    }

    commit('loginSuccess', { name: 'loginStepTwo', hint, verificationId });
  },

  /**
   * Third step in login flow; resolves the sign in after the sms code has been verified in verifyPhoneSMS.
   */
  async loginStepThree(
    { commit },
    { multiFactorAssertion }: { multiFactorAssertion: firebase.auth.MultiFactorAssertion },
  ): Promise<void> {
    commit('loginProcessing', { name: 'loginStepThree' });

    if (!resolver) {
      commit('loginError', { name: 'loginStepThree', error: Error('There was a problem login in. Error #2 (resolver).') });
      return;
    }

    const [signInError] = await to(resolver.resolveSignIn(multiFactorAssertion));
    if (signInError) {
      commit('loginError', { name: 'loginStepThree', error: signInError });
      return;
    }

    commit('loginSuccess', { name: 'loginStepThree' });
  },

  /**
   * First step in enrolling an user to specific multifactor (phone).
   */
  async enrollSecondFactor(
    { commit },
    { phoneNumber, appVerifier }:
      { phoneNumber: string, appVerifier: firebase.auth.RecaptchaVerifier_Instance },
  ): Promise<void> {
    commit('loginProcessing', { name: 'enroll' });

    const [error, session] = await to(bloqifyAuth.currentUser!.multiFactor.getSession());
    if (error) {
      commit('loginError', { name: 'enroll', error });
    }

    const phoneInfoOptions = {
      phoneNumber,
      session,
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

    // Send SMS verification code.
    const [verifyError, verificationId] = await to(phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, appVerifier));
    if (verifyError) {
      commit('loginError', { name: 'enroll', error: verifyError });
      return;
    }

    commit('loginSuccess', { name: 'enroll', verificationId });
  },

  /**
   * Second and final step enrolling an user into phone multifactor (after verifying the sms code with verifyPhoneSMS).
   */
  async enrollSecondFactorStepTwo(
    { commit },
    { multiFactorAssertion }: { multiFactorAssertion: firebase.auth.MultiFactorAssertion },
  ): Promise<void> {
    commit('loginProcessing', { name: 'enrollStepTwo' });

    const [enrollError] = await to(bloqifyAuth.currentUser!.multiFactor.enroll(multiFactorAssertion));
    if (enrollError) {
      commit('loginError', { name: 'enrollStepTwo', error: enrollError });
      return;
    }

    commit('loginSuccess', { name: 'enrollStepTwo' });
  },

  /**
   * The previous step to verify if the sms code is correct.
   */
  verifyPhoneSMS(
    { commit },
    { verificationId, verificationCode, calledBy }: { verificationId: string, verificationCode: string, calledBy: string },
  ): void {
    commit('verifySMSProcessing', { name: 'verifyPhoneSMS', calledBy });

    let multiFactorAssertion;
    try {
      const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
      multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    } catch (e) {
      commit('verifySMSError', { name: 'verifyPhoneSMS', error: e });
      return;
    }

    commit('verifySMSSuccess', { multiFactorAssertion, name: 'verifyPhoneSMS', calledBy });
  },

  /**
   * Logout.
   */
  async logOut(
    { commit },
    { redirect }: { redirect?: string } = {},
  ): Promise<void> {
    commit('logoutProcessing');
    const [error] = await to<void, firebase.auth.Error>(bloqifyAuth.signOut());

    if (error) {
      commit('logoutError', error.message);
    } else {
      commit('logoutSuccess');

      if (redirect) {
        VueRouter.replace(redirect);
      }
    }
  },

  /**
   * Remove user.
   */
  async removeManager({ commit }, { userId }: { userId: string }): Promise<void> {
    commit('removeManagerProcessing');

    const [error, result] = await to(bloqifyFunctions.httpsCallable('removeUser')({ userId }));

    if (error) {
      commit('removeManagerError', error);
    } else {
      commit('removeManagerSuccess', result!.data as any[]);
    }
  },

  /**
   * Either:
   * - Create a new user with email, password and role or
   * - Set a new role to an existing user
   */
  async setManagerRole(
    { commit },
    { uid, email, role, password, identifier }: { uid?: string, email?: string, role: string, password?: string, identifier?: string },
  ): Promise<void> {
    commit('setManagerRoleProcessing', uid || email);

    const [error, data] = await to(
      bloqifyFunctions.httpsCallable('setManagerRole')({ uid, email, role, password, identifier }),
    );

    if (error) {
      commit('setManagerRoleError', error.message);
    } else {
      commit('setManagerRoleSuccess', data!.data);
    }
  },

  /**
   * Settings form submissions.
   */
  async submitGeneralSettings({ commit }, settings: Settings): Promise<void> {
    commit('submitGeneralSettingsProcessing');

    const [error, success] = await to(bloqifyFirestore.collection('settings').doc('admin').update(settings));

    if (error) {
      commit('submitGeneralSettingsError', error.message);
      return;
    }

    commit('submitGeneralSettingsSuccess', success);
  },

  /**
   * Security settings form submissions.
   */
  async changePassword(
    { commit, state },
    { newPassword }: { newPassword: string },
  ): Promise<void> {
    commit('changePasswordProcessing');

    if (state.manager !== null) {
      const user = bloqifyAuth.currentUser as firebase.User;
      const [updatePasswordError] = await to<void, firebase.auth.Error>(user.updatePassword(newPassword));

      if (updatePasswordError) {
        commit('changePasswordError', updatePasswordError.message);
      } else {
        commit('changePasswordSuccess');
      }
    } else {
      commit('changePasswordError', 'Not allowed since your identify is unknown.');
    }
  },

  async sendPasswordResetEmail(
    { commit },
    { email }: { email: string },
  ): Promise<void> {
    commit('sendPasswordResetEmailProcessing');

    const [error] = await to<void, firebase.auth.Error>(bloqifyAuth.sendPasswordResetEmail(email));

    if (error) {
      // ⚠️ The e-mail entered might not be linked to an account,
      // but for the sake of security, we should NOT leak this
      // information to the frontend, so just act like success
      if (error.code === 'auth/user-not-found') {
        commit('sendPasswordResetEmailSuccess');
      } else {
        commit('sendPasswordResetEmailError', error.message);
      }
    } else {
      commit('sendPasswordResetEmailSuccess');
    }
  },

  /**
   * Approve sensitive data change.
   */
  async approveSensitiveDataChange(
    { commit },
    { id, investor, newData }: any,
  ): Promise<void> {
    commit('approveSensitiveDataChangeProcessing');

    const [removeDataChangeRequestError] = await to(bloqifyFirestore.collection('dataChangeRequests').doc(id).update({
      status: 'approved',
    }));
    const [updateInvestorError] = await to(bloqifyFirestore.collection('investors').doc(investor.id).update(newData));

    if (removeDataChangeRequestError) {
      commit('approveSensitiveDataChangeError', removeDataChangeRequestError.message);
      return;
    }

    if (updateInvestorError) {
      commit('approveSensitiveDataChangeError', updateInvestorError.message);
      return;
    }

    commit('approveSensitiveDataChangeSuccess');
  },

  /**
   * Decline sensitive data change.
   */
  async rejectSensitiveDataChange(
    { commit, state },
    { id }: any,
  ): Promise<void> {
    commit('rejectSensitiveDataChangeProcessing');

    const [removeDataChangeRequestError] = await to(bloqifyFirestore.collection('dataChangeRequests').doc(id).update({
      status: 'rejected',
    }));

    if (removeDataChangeRequestError) {
      commit('rejectSensitiveDataChangeError', removeDataChangeRequestError.message);
      return;
    }

    commit('rejectSensitiveDataChangeSuccess');
  },

  /**
   * Set a persistent filter
   */
  setFilter(
    { commit },
    data: { collection: string, field: string, value: string | boolean },
  ): void {
    commit('setFilter', data);
  },

  /**
   * Reset a filter to its initial state
   */
  resetFilters(
    { commit },
    data: { collection: string },
  ): void {
    commit('resetFilters', data);
  },

  /**
   * Get backups to restore
   */
  async fetchBackups({ commit }): Promise<void> {
    commit('fetchBackupsProcessing');

    const bucketName: string = `gs://${(bloqify.options as any).projectId}-backups`;

    const [fetchBackupsError, fetchBackupsSuccess] = await to(bloqify.storage(bucketName).ref('/backups').listAll());

    if (fetchBackupsError) {
      commit('fetchBackupsError', fetchBackupsError.message);
      return;
    }

    commit('fetchBackupsSuccess', fetchBackupsSuccess?.prefixes);
  },

  /**
   * Start restore backup process.
   */
  async startRestoreBackup({ commit }, { date }): Promise<void> {
    commit('restoreBackupProcessing');

    const [restoreBackupError, restoreBackupSuccess] = await to<any>(
      bloqifyFunctions.httpsCallable('restoreBackup')({ date }),
    );

    if (restoreBackupError) {
      commit('restoreBackupError', restoreBackupError);
      return;
    }

    const [createRestorationDocError, createRestorationDocSuccess] = await to(
      bloqifyFirestore.collection('restoration').add(restoreBackupSuccess?.data),
    );

    if (createRestorationDocError) {
      commit('restoreBackupError', createRestorationDocError);
      return;
    }

    commit('restoreBackupSuccess', { ...restoreBackupSuccess.data, restorationId: createRestorationDocSuccess?.id });
  },

  /**
   * Fetch restore backup process status.
   */
  async fetchRestoreBackupStatus({ commit, state }): Promise<void> {
    commit('restoreBackupStatusProcessing');

    const [restoreBackupStatusError, restoreBackupStatusSuccess] = await to(
      bloqifyFunctions.httpsCallable('operationStatus')({ name: state.restoreBackup?.payload?.name, type: 'import' }),
    );

    if (restoreBackupStatusError) {
      commit('restoreBackupStatusError', restoreBackupStatusError);
      return;
    }

    const [updateRestorationDocError] = await to(
      bloqifyFirestore.collection('restoration').doc(state.restoreBackup.payload.restorationId).set(restoreBackupStatusSuccess?.data),
    );

    if (updateRestorationDocError) {
      commit('restoreBackupStatusError', updateRestorationDocError);
      return;
    }

    commit('restoreBackupStatusSuccess', restoreBackupStatusSuccess?.data);
  },

  /**
   * Settings form submissions.
   */
  async sendNotification({ commit }, notification: Notification): Promise<void> {
    commit('sendNotificationProcessing');

    const [error, result] = await to<any>(bloqifyFunctions.httpsCallable('sendNotification')(notification));

    if (error) {
      commit('sendNotificationError', error.message);
      return;
    }

    commit('sendNotificationSuccess', result);
  },
};
