import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset, AccruedRoyalty } from '@/models/assets/Asset';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import { generateState, mutateState } from '../utils/skeleton';

export interface DeleteAccruedRoyalty {
  assetId: string;
  accruedRoyaltyId: string;
}
export interface CreateAccruedRoyalty {
  assetId: string;
  amount: number;
  date: Date;
}
export interface ModifyAccruedRoyalty {
  assetId: string;
  accruedRoyaltyId: string;
  amount: number;
}
interface TransactionBatch {
  operation: 'set' | 'update';
  documentReference: firebase.firestore.DocumentReference;
  data: { [key: string]: any };
}
const executeBatch = (transaction: firebase.firestore.Transaction, data: TransactionBatch[]): void => {
  data.forEach((row): void => {
    switch (row.operation) {
      case 'set':
        transaction.set(row.documentReference, row.data);
        break;
      case 'update':
        transaction.update(row.documentReference, row.data);
        break;
      default:
        break;
    }
  });
};

const SET_ACCRUEDROYALTY = 'SET_ACCRUEDROYALTY';

export default {
  state: generateState(),
  mutations: {
    [SET_ACCRUEDROYALTY](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createAccruedRoyalty(
      { commit },
      { assetId, amount, date }: CreateAccruedRoyalty,
    ): Promise<void> {
      commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Processing, operation: 'createAccruedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const incomingAccruedRoyalty = new BigNumber(amount);
        const accruedRoyaltyRef = getAsset.ref.collection('accruedRoyaltys').doc();
        const [getAccruedRoyaltyHistError, getAccruedRoyaltyHist] = await to((getAsset.ref.collection('accruedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getAccruedRoyaltyHistError) {
          throw Error(getAccruedRoyaltyHistError?.message);
        }

        const lastAccruedRoyalty = date.valueOf() >= (getAccruedRoyaltyHist?.docs[0]?.get('appliedDateTime')?.toDate()?.valueOf() || -Infinity);

        if (lastAccruedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              accruedRoyaltyTotal: incomingAccruedRoyalty.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'set',
          documentReference: accruedRoyaltyRef,
          data: {
            asset: getAsset.ref,
            deleted: false,
            amount: incomingAccruedRoyalty.toNumber(),
            appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
            createdDateTime: now,
            updatedDateTime: now,
          } as AccruedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createAccruedRoyalty' });
      }
      return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Success, operation: 'createAccruedRoyalty' });
    },

    async modifyAccruedRoyalty(
      { commit },
      { assetId, accruedRoyaltyId, amount }: ModifyAccruedRoyalty,
    ): Promise<void> {
      commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Processing, operation: 'modifyAccruedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getAccruedRoyaltyError, getAccruedRoyalty] = await to(transaction.get(getAsset.ref.collection('accruedRoyaltys')
                                                                                               .doc(accruedRoyaltyId)));
        if (getAccruedRoyaltyError || !getAccruedRoyalty || !getAccruedRoyalty.exists) {
          throw Error(getAccruedRoyaltyError?.message || 'Accrued Royalty not found');
        }
        const accruedRoyalty = getAccruedRoyalty.data() as AccruedRoyalty;
        if (accruedRoyalty.deleted) {
          throw Error('Accrued Royalty erased');
        }

        const [getAccruedRoyaltyHistError, getAccruedRoyaltyHist] = await to((getAsset.ref.collection('accruedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getAccruedRoyaltyHistError || !getAccruedRoyaltyHist || !getAccruedRoyaltyHist.size) {
          throw Error(getAccruedRoyaltyHistError?.message || 'Accrued Royalty not found');
        }

        const lastAccruedRoyalty = getAccruedRoyaltyHist.docs[0].id === getAccruedRoyalty.id;
        const incomingAccruedRoyaltyAmount = new BigNumber(amount);

        if (lastAccruedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              accruedRoyaltyTotal: incomingAccruedRoyaltyAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getAccruedRoyalty.ref,
          data: {
            amount: incomingAccruedRoyaltyAmount.toNumber(),
            updatedDateTime: now,
          } as AccruedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Error, payload: transactionError, operation: 'modifyAccruedRoyalty' });
      }
      return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Success, operation: 'modifyAccruedRoyalty' });
    },

    async deleteAccruedRoyalty(
      { commit },
      { assetId, accruedRoyaltyId }: DeleteAccruedRoyalty,
    ): Promise<void> {
      commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Processing, operation: 'deleteAccruedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getAccruedRoyaltyError, getAccruedRoyalty] = await to(transaction.get(getAsset.ref.collection('accruedRoyaltys')
                                                                                               .doc(accruedRoyaltyId)));
        if (getAccruedRoyaltyError || !getAccruedRoyalty || !getAccruedRoyalty.exists) {
          throw Error(getAccruedRoyaltyError?.message || 'Accrued Royalty not found');
        }
        const assetAccruedRoyalty = getAccruedRoyalty.data() as AccruedRoyalty;

        if (assetAccruedRoyalty.deleted) {
          throw Error('Accrued Royalty already erased');
        }

        const [getAccruedRoyaltyHistError, getAccruedRoyaltyHist] = await to((getAsset.ref.collection('accruedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getAccruedRoyaltyHistError || !getAccruedRoyaltyHist || !getAccruedRoyaltyHist.size) {
          throw Error(getAccruedRoyaltyHistError?.message || 'Accrued Royalty not found');
        }

        const lastAccruedRoyalty = getAccruedRoyaltyHist.docs[0].id === getAccruedRoyalty.id;
        const previousAmount = new BigNumber(getAccruedRoyaltyHist.docs[1]?.get('amount') || 0);

        if (lastAccruedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              accruedRoyaltyTotal: previousAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getAccruedRoyalty.ref,
          data: {
            deleted: true,
            updatedDateTime: now,
          } as AccruedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteAccruedRoyalty' });
      }
      return commit(SET_ACCRUEDROYALTY, { status: DataContainerStatus.Success, operation: 'deleteAccruedRoyalty' });
    },
  },
};
