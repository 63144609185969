import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { Asset } from '@/models/assets/Asset';
import { Item } from '@/models/assets/Item';
import { capitalize } from '@/filters/string';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

const SET_ITEM = 'SET_ITEM';

export interface UpdateItemParam {
  assetId: string;
  subCollection: string;
  itemId: string;
  payload: object;
}

export interface DeleteItemParam {
  assetId: string;
  subCollection: string;
  itemId: string;
}

export interface AddItemParam {
  assetId: string;
  subCollection: string;
  name: string;
  amount: number;
  date: Date;
}

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_ITEM](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addItem(
      { commit }, { assetId, subCollection, name, amount, date }: AddItemParam,
    ): Promise<void> {
      commit(SET_ITEM, { status: DataContainerStatus.Processing, operation: `add${capitalize(subCollection)}` });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId) as DocumentReference<Asset>;
      const subCollectionRef = assetRef.collection(subCollection);
      const dateNow = Timestamp.now();
      const dateStamp = Timestamp.fromDate(date);

      const [getAssetError, getAssetData] = await to(assetRef.get());

      if (getAssetError || !getAssetData?.exists) {
        throw Error(getAssetError?.message || 'Asset not found');
      }

      const [addItemError, addItemSuccess] = await to(
        subCollectionRef.add({
          asset: assetRef,
          name,
          amount,
          date: dateStamp,
          createdDateTime: dateNow,
          deleted: false,
        }),
      );

      if (addItemError) {
        return commit(SET_ITEM, {
          status: DataContainerStatus.Error,
          payload: addItemError,
          operation: `add${capitalize(subCollection)}`,
        });
      }

      return commit(SET_ITEM, {
        status: DataContainerStatus.Success,
        operation: `add${capitalize(subCollection)}`,
      });
    },
    async updateItem(
      { commit },
      { payload, assetId, itemId, subCollection }: UpdateItemParam,
    ): Promise<void> {
      commit(SET_ITEM, {
        status: DataContainerStatus.Processing,
        operation: `update${capitalize(subCollection)}`,
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const subCollectionItemRef = assetRef.collection(subCollection).doc(itemId) as DocumentReference<Item>;

      const [updateItemError, updateItemSuccess] = await to(
        subCollectionItemRef.update(payload),
      );

      if (updateItemError) {
        return commit(SET_ITEM, {
          status: DataContainerStatus.Error,
          payload: updateItemError,
          operation: `update${capitalize(subCollection)}`,
        });
      }

      return commit(SET_ITEM, {
        status: DataContainerStatus.Success,
        payload: updateItemSuccess,
        operation: `update${capitalize(subCollection)}`,
      });
    },
    async deleteItem(
      { commit },
      { assetId, itemId, subCollection }: DeleteItemParam,
    ): Promise<void> {
      commit(SET_ITEM, {
        status: DataContainerStatus.Processing,
        operation: `delete${capitalize(subCollection)}`,
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const subCollectionItemRef = assetRef.collection(subCollection).doc(itemId) as DocumentReference<Item>;

      const [deleteItemError, deleteItemSuccess] = await to(
        subCollectionItemRef.update({ deleted: true }),
      );

      if (deleteItemError) {
        return commit(SET_ITEM, {
          status: DataContainerStatus.Error,
          payload: deleteItemError,
          operation: `delete${capitalize(subCollection)}`,
        });
      }

      return commit(SET_ITEM, {
        status: DataContainerStatus.Success,
        payload: deleteItemSuccess,
        operation: `delete${capitalize(subCollection)}`,
      });
    },
  },
};
