import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset, DistributedRoyalty } from '@/models/assets/Asset';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import { generateState, mutateState } from '../utils/skeleton';

export interface DeleteDistributedRoyalty {
  assetId: string;
  distributedRoyaltyId: string;
}
export interface CreateDistributedRoyalty {
  assetId: string;
  amount: number;
  date: Date;
}
export interface ModifyDistributedRoyalty {
  assetId: string;
  distributedRoyaltyId: string;
  amount: number;
}
interface TransactionBatch {
  operation: 'set' | 'update';
  documentReference: firebase.firestore.DocumentReference;
  data: { [key: string]: any };
}
const executeBatch = (transaction: firebase.firestore.Transaction, data: TransactionBatch[]): void => {
  data.forEach((row): void => {
    switch (row.operation) {
      case 'set':
        transaction.set(row.documentReference, row.data);
        break;
      case 'update':
        transaction.update(row.documentReference, row.data);
        break;
      default:
        break;
    }
  });
};

const SET_DISTRIBUTEDROYALTY = 'SET_DISTRIBUTEDROYALTY';

export default {
  state: generateState(),
  mutations: {
    [SET_DISTRIBUTEDROYALTY](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createDistributedRoyalty(
      { commit },
      { assetId, amount, date }: CreateDistributedRoyalty,
    ): Promise<void> {
      commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Processing, operation: 'createDistributedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const incomingDistributedRoyalty = new BigNumber(amount);
        const distributedRoyaltyRef = getAsset.ref.collection('distributedRoyaltys').doc();
        const [getDistributedRoyaltyHistError, getDistributedRoyaltyHist] = await to((getAsset.ref.collection('distributedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getDistributedRoyaltyHistError) {
          throw Error(getDistributedRoyaltyHistError?.message);
        }

        const lastDistributedRoyalty = date.valueOf() >= (getDistributedRoyaltyHist?.docs[0]?.get('appliedDateTime')?.toDate()
                                        ?.valueOf() || -Infinity);

        if (lastDistributedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              distributedRoyaltyTotal: incomingDistributedRoyalty.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'set',
          documentReference: distributedRoyaltyRef,
          data: {
            asset: getAsset.ref,
            deleted: false,
            amount: incomingDistributedRoyalty.toNumber(),
            appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
            createdDateTime: now,
            updatedDateTime: now,
          } as DistributedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_DISTRIBUTEDROYALTY,
            { status: DataContainerStatus.Error, payload: transactionError, operation: 'createDistributedRoyalty' });
      }
      return commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Success, operation: 'createDistributedRoyalty' });
    },

    async modifyDistributedRoyalty(
      { commit },
      { assetId, distributedRoyaltyId, amount }: ModifyDistributedRoyalty,
    ): Promise<void> {
      commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Processing, operation: 'modifyDistributedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getDistributedRoyaltyError, getDistributedRoyalty] = await to(transaction.get(getAsset.ref.collection('distributedRoyaltys')
                                                                                               .doc(distributedRoyaltyId)));
        if (getDistributedRoyaltyError || !getDistributedRoyalty || !getDistributedRoyalty.exists) {
          throw Error(getDistributedRoyaltyError?.message || 'Distributed Royalty not found');
        }
        const distributedRoyalty = getDistributedRoyalty.data() as DistributedRoyalty;
        if (distributedRoyalty.deleted) {
          throw Error('Distributed Royalty erased');
        }

        const [getDistributedRoyaltyHistError, getDistributedRoyaltyHist] = await to((getAsset.ref.collection('distributedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getDistributedRoyaltyHistError || !getDistributedRoyaltyHist || !getDistributedRoyaltyHist.size) {
          throw Error(getDistributedRoyaltyHistError?.message || 'Distributed Royalty not found');
        }

        const lastDistributedRoyalty = getDistributedRoyaltyHist.docs[0].id === getDistributedRoyalty.id;
        const incomingDistributedRoyaltyAmount = new BigNumber(amount);

        if (lastDistributedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              distributedRoyaltyTotal: incomingDistributedRoyaltyAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getDistributedRoyalty.ref,
          data: {
            amount: incomingDistributedRoyaltyAmount.toNumber(),
            updatedDateTime: now,
          } as DistributedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_DISTRIBUTEDROYALTY,
            { status: DataContainerStatus.Error, payload: transactionError, operation: 'modifyDistributedRoyalty' });
      }
      return commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Success, operation: 'modifyDistributedRoyalty' });
    },

    async deleteDistributedRoyalty(
      { commit },
      { assetId, distributedRoyaltyId }: DeleteDistributedRoyalty,
    ): Promise<void> {
      commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Processing, operation: 'deleteDistributedRoyalty' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getDistributedRoyaltyError, getDistributedRoyalty] = await to(transaction.get(getAsset.ref.collection('distributedRoyaltys')
                                                                                               .doc(distributedRoyaltyId)));
        if (getDistributedRoyaltyError || !getDistributedRoyalty || !getDistributedRoyalty.exists) {
          throw Error(getDistributedRoyaltyError?.message || 'Distributed Royalty not found');
        }
        const assetDistributedRoyalty = getDistributedRoyalty.data() as DistributedRoyalty;

        if (assetDistributedRoyalty.deleted) {
          throw Error('Distributed Royalty already erased');
        }

        const [getDistributedRoyaltyHistError, getDistributedRoyaltyHist] = await to((getAsset.ref.collection('distributedRoyaltys')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getDistributedRoyaltyHistError || !getDistributedRoyaltyHist || !getDistributedRoyaltyHist.size) {
          throw Error(getDistributedRoyaltyHistError?.message || 'Distributed Royalty not found');
        }

        const lastDistributedRoyalty = getDistributedRoyaltyHist.docs[0].id === getDistributedRoyalty.id;
        const previousAmount = new BigNumber(getDistributedRoyaltyHist.docs[1]?.get('amount') || 0);

        if (lastDistributedRoyalty) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              distributedRoyaltyTotal: previousAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getDistributedRoyalty.ref,
          data: {
            deleted: true,
            updatedDateTime: now,
          } as DistributedRoyalty,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_DISTRIBUTEDROYALTY,
            { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteDistributedRoyalty' });
      }
      return commit(SET_DISTRIBUTEDROYALTY, { status: DataContainerStatus.Success, operation: 'deleteDistributedRoyalty' });
    },
  },
};
