import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import identificationRequest from './identificationRequest';
import payment from './payment';
import calledCapital from './calledCapital';
import netAssetValue from './netAssetValue';
import accruedRoyalty from './accruedRoyaltys';
import distributedRoyalty from './distributedRoyaltys';
import item from './item';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  identificationRequest,
  payment,
  netAssetValue,
  calledCapital,
  accruedRoyalty,
  distributedRoyalty,
  item,
};
