import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset, NetAssetValue } from '@/models/assets/Asset';
import { DataContainerStatus } from '@/models/Common';
import { Investment } from '@/models/investments/Investment';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import { generateState, mutateState } from '../utils/skeleton';

export interface DeleteNetAssetValue {
  assetId: string,
  netAssetValueId: string,
}

export interface CreateNetAssetValue {
  assetId: string;
  amount: number;
  date: Date;
}

export interface ModifyNetAssetValue {
  assetId: string,
  netAssetValueId: string,
  amount: number,
}
interface TransactionBatch {
  operation: 'set' | 'update';
  documentReference: firebase.firestore.DocumentReference;
  data: { [key: string]: any };
}
const executeBatch = (transaction: firebase.firestore.Transaction, data: TransactionBatch[]): void => {
  data.forEach((row): void => {
    switch (row.operation) {
      case 'set':
        transaction.set(row.documentReference, row.data);
        break;
      case 'update':
        transaction.update(row.documentReference, row.data);
        break;
      default:
        break;
    }
  });
};

const SET_NETVALUE = 'SET_NETVALUE';

export default {
  state: generateState(),
  mutations: {
    [SET_NETVALUE](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createNetAssetValue(
      { commit },
      { assetId, amount, date }: CreateNetAssetValue,
    ): Promise<void> {
      commit(SET_NETVALUE, { status: DataContainerStatus.Processing, operation: 'createNetAssetValue' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const incomingNetAssetValue = new BigNumber(amount);
        const netAssetValueRef = getAsset.ref.collection('netAssetValues').doc();
        const [getNetAssetValueHistError, getNetAssetValueHist] = await to((getAsset.ref.collection('netAssetValues')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getNetAssetValueHistError) {
          throw Error(getNetAssetValueHistError?.message);
        }

        const lastNetAssetValue = date.valueOf() >= (getNetAssetValueHist?.docs[0]?.get('appliedDateTime')?.toDate()?.valueOf() || -Infinity);

        if (lastNetAssetValue) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              netAssetValueTotal: incomingNetAssetValue.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'set',
          documentReference: netAssetValueRef,
          data: {
            asset: getAsset.ref,
            deleted: false,
            amount: incomingNetAssetValue.toNumber(),
            appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
            createdDateTime: now,
            updatedDateTime: now,
          } as NetAssetValue,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_NETVALUE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createNetAssetValue' });
      }
      return commit(SET_NETVALUE, { status: DataContainerStatus.Success, operation: 'createNetAssetValue' });
    },

    async modifyNetAssetValue(
      { commit },
      { assetId, netAssetValueId, amount }: ModifyNetAssetValue,
    ): Promise<void> {
      commit(SET_NETVALUE, { status: DataContainerStatus.Processing, operation: 'modifyNetAssetValue' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getNetAssetValueError, getNetAssetValue] = await to(transaction.get(getAsset.ref.collection('netAssetValues')
                                                                                               .doc(netAssetValueId)));
        if (getNetAssetValueError || !getNetAssetValue || !getNetAssetValue.exists) {
          throw Error(getNetAssetValueError?.message || 'Net asset value not found');
        }
        const netAssetValue = getNetAssetValue.data() as NetAssetValue;
        if (netAssetValue.deleted) {
          throw Error('Net asset value erased');
        }

        const [getNetAssetValueHistError, getNetAssetValueHist] = await to((getAsset.ref.collection('netAssetValues')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getNetAssetValueHistError || !getNetAssetValueHist || !getNetAssetValueHist.size) {
          throw Error(getNetAssetValueHistError?.message || 'Net asset values not found');
        }

        const lastNetAssetValue = getNetAssetValueHist.docs[0].id === getNetAssetValue.id;
        const incomingNetAssetValue = new BigNumber(amount);

        if (lastNetAssetValue) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              netAssetValueTotal: incomingNetAssetValue.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getNetAssetValue.ref,
          data: {
            amount: incomingNetAssetValue.toNumber(),
            updatedDateTime: now,
          } as NetAssetValue,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_NETVALUE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'modifyNetAssetValue' });
      }
      return commit(SET_NETVALUE, { status: DataContainerStatus.Success, operation: 'modifyNetAssetValue' });
    },

    async deleteNetAssetValue(
      { commit },
      { assetId, netAssetValueId }: DeleteNetAssetValue,
    ): Promise<void> {
      commit(SET_NETVALUE, { status: DataContainerStatus.Processing, operation: 'deleteNetAssetValue' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getNetAssetValueError, getNetAssetValue] = await to(transaction.get(getAsset.ref.collection('netAssetValues')
                                                                                               .doc(netAssetValueId)));
        if (getNetAssetValueError || !getNetAssetValue || !getNetAssetValue.exists) {
          throw Error(getNetAssetValueError?.message || 'Net asset value not found');
        }
        const netAssetValue = getNetAssetValue.data() as NetAssetValue;

        if (netAssetValue.deleted) {
          throw Error('Net asset value already erased');
        }

        const [getNetAssetValueHistError, getNetAssetValueHist] = await to((getAsset.ref.collection('netAssetValues')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getNetAssetValueHistError || !getNetAssetValueHist || !getNetAssetValueHist.size) {
          throw Error(getNetAssetValueHistError?.message || 'Net asset values not found');
        }

        const lastNetAssetValue = getNetAssetValueHist.docs[0].id === getNetAssetValue.id;
        const previousAmount = new BigNumber(getNetAssetValueHist.docs[1]?.get('amount') || 0);

        if (lastNetAssetValue) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              netAssetValueTotal: previousAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getNetAssetValue.ref,
          data: {
            deleted: true,
            updatedDateTime: now,
          } as NetAssetValue,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_NETVALUE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteNetAssetValue' });
      }
      return commit(SET_NETVALUE, { status: DataContainerStatus.Success, operation: 'deleteNetAssetValue' });
    },
  },
};
