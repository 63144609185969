import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset, CalledCapital } from '@/models/assets/Asset';
import { DataContainerStatus } from '@/models/Common';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import { generateState, mutateState } from '../utils/skeleton';

export interface DeleteCalledCapital {
  assetId: string;
  calledCapitalId: string;
}
export interface CreateCalledCapital {
  assetId: string;
  amount: number;
  date: Date;
}
export interface ModifyCalledCapital {
  assetId: string;
  calledCapitalId: string;
  amount: number;
}
interface TransactionBatch {
  operation: 'set' | 'update';
  documentReference: firebase.firestore.DocumentReference;
  data: { [key: string]: any };
}
const executeBatch = (transaction: firebase.firestore.Transaction, data: TransactionBatch[]): void => {
  data.forEach((row): void => {
    switch (row.operation) {
      case 'set':
        transaction.set(row.documentReference, row.data);
        break;
      case 'update':
        transaction.update(row.documentReference, row.data);
        break;
      default:
        break;
    }
  });
};

const SET_CALLEDCAPITAL = 'SET_CALLEDCAPITAL';

export default {
  state: generateState(),
  mutations: {
    [SET_CALLEDCAPITAL](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createCalledCapital(
      { commit },
      { assetId, amount, date }: CreateCalledCapital,
    ): Promise<void> {
      commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Processing, operation: 'createCalledCapital' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const incomingCalledCapital = new BigNumber(amount);
        const calledCapitalRef = getAsset.ref.collection('calledCapitals').doc();
        const [getCalledCapitalHistError, getCalledCapitalHist] = await to((getAsset.ref.collection('calledCapitals')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getCalledCapitalHistError) {
          throw Error(getCalledCapitalHistError?.message);
        }

        const lastCalledCapital = date.valueOf() >= (getCalledCapitalHist?.docs[0]?.get('appliedDateTime')?.toDate()?.valueOf() || -Infinity);

        if (lastCalledCapital) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              calledCapitalTotal: incomingCalledCapital.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'set',
          documentReference: calledCapitalRef,
          data: {
            asset: getAsset.ref,
            deleted: false,
            amount: incomingCalledCapital.toNumber(),
            appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
            createdDateTime: now,
            updatedDateTime: now,
          } as CalledCapital,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createCalledCapital' });
      }
      return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Success, operation: 'createCalledCapital' });
    },

    async modifyCalledCapital(
      { commit },
      { assetId, calledCapitalId, amount }: ModifyCalledCapital,
    ): Promise<void> {
      commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Processing, operation: 'modifyCalledCapital' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getCalledCapitalError, getCalledCapital] = await to(transaction.get(getAsset.ref.collection('calledCapitals')
                                                                                               .doc(calledCapitalId)));
        if (getCalledCapitalError || !getCalledCapital || !getCalledCapital.exists) {
          throw Error(getCalledCapitalError?.message || 'Called capital not found');
        }
        const calledCapital = getCalledCapital.data() as CalledCapital;
        if (calledCapital.deleted) {
          throw Error('Called capital erased');
        }

        const [getCalledCapitalHistError, getCalledCapitalHist] = await to((getAsset.ref.collection('calledCapitals')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getCalledCapitalHistError || !getCalledCapitalHist || !getCalledCapitalHist.size) {
          throw Error(getCalledCapitalHistError?.message || 'Called capitals not found');
        }

        const lastCalledCapital = getCalledCapitalHist.docs[0].id === getCalledCapital.id;
        const incomingCalledCapitalAmount = new BigNumber(amount);

        if (lastCalledCapital) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              calledCapitalTotal: incomingCalledCapitalAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getCalledCapital.ref,
          data: {
            amount: incomingCalledCapitalAmount.toNumber(),
            updatedDateTime: now,
          } as CalledCapital,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Error, payload: transactionError, operation: 'modifyCalledCapital' });
      }
      return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Success, operation: 'modifyCalledCapital' });
    },

    async deleteCalledCapital(
      { commit },
      { assetId, calledCapitalId }: DeleteCalledCapital,
    ): Promise<void> {
      commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Processing, operation: 'deleteCalledCapital' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = [] as TransactionBatch [];
        const now = firebase.firestore.Timestamp.now();

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getCalledCapitalError, getCalledCapital] = await to(transaction.get(getAsset.ref.collection('calledCapitals')
                                                                                               .doc(calledCapitalId)));
        if (getCalledCapitalError || !getCalledCapital || !getCalledCapital.exists) {
          throw Error(getCalledCapitalError?.message || 'Called capital not found');
        }
        const assetCalledCapital = getCalledCapital.data() as CalledCapital;

        if (assetCalledCapital.deleted) {
          throw Error('Called capital already erased');
        }

        const [getCalledCapitalHistError, getCalledCapitalHist] = await to((getAsset.ref.collection('calledCapitals')
                                                                                        .where('deleted', '==', false)
                                                                                        .orderBy('appliedDateTime', 'desc')
                                                                                        .get()));

        if (getCalledCapitalHistError || !getCalledCapitalHist || !getCalledCapitalHist.size) {
          throw Error(getCalledCapitalHistError?.message || 'Called capitals not found');
        }

        const lastCalledCapital = getCalledCapitalHist.docs[0].id === getCalledCapital.id;
        const previousAmount = new BigNumber(getCalledCapitalHist.docs[1]?.get('amount') || 0);

        if (lastCalledCapital) {
          transactionBatch.push({
            operation: 'update',
            documentReference: getAsset.ref,
            data: {
              calledCapitalTotal: previousAmount.toNumber(),
              updatedDateTime: now,
            } as Asset,
          });
        }
        transactionBatch.push({
          operation: 'update',
          documentReference: getCalledCapital.ref,
          data: {
            deleted: true,
            updatedDateTime: now,
          } as CalledCapital,
        });

        executeBatch(transaction, transactionBatch);
      }));
      if (transactionError) {
        return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteCalledCapital' });
      }
      return commit(SET_CALLEDCAPITAL, { status: DataContainerStatus.Success, operation: 'deleteCalledCapital' });
    },
  },
};
