import firebase from 'firebase/app';
import { ManagerRole } from '@/models/manager/Manager';
import { CurrentManager } from '@/models/manager/CurrentManager';

export class CurrentManagerHydrator {
  /**
   * Create new instance based on Firebase user type (i.e.
   * the regular when using the default Firebase application, in
   * contrast to the user record the Firebase admin returns).
   */
  public static async hydrate(
    firebaseUser: firebase.User,
    forceRefresh: boolean = false,
  ): Promise<CurrentManager> {
    const role: ManagerRole = await CurrentManagerHydrator.getRole(firebaseUser, forceRefresh);

    // Error
    if (!firebaseUser.email) {
      throw new Error(`Current Firebase user ${firebaseUser.uid} has no e-mail address.`);
    }

    return new CurrentManager(
      role,
      firebaseUser.uid,
      firebaseUser.email,
      firebaseUser.metadata.creationTime || undefined,
      firebaseUser.metadata.lastSignInTime || undefined,
    );
  }

  /**
   * Set role in current instance.
   */
  private static async getRole(
    firebaseUser: firebase.User,
    forceRefresh: boolean = false,
  ): Promise<ManagerRole> {
    const tokenResult = await firebaseUser.getIdTokenResult(forceRefresh);

    if (tokenResult.claims.superadmin) {
      return ManagerRole.Superadmin;
    }

    if (tokenResult.claims.admin) {
      return ManagerRole.Admin;
    }

    if (tokenResult.claims.manager) {
      return ManagerRole.Manager;
    }

    return ManagerRole.Editor;
  }
}
